import assertion, { AssertionArgs } from '../assertion';
import Form from '../components/Form';
import Layout from '../components/Layout';
import registration, { RegistrationArgs } from '../registration';
import { RegistryEntry } from '../types';

export interface HomeProps {
  version?: string;
}

export default function Home({ version }: HomeProps) {
  const onRegistration = (args: RegistrationArgs, registry: RegistryEntry[]) =>
    registration(args, registry);

  const onAssertion = (
    args: AssertionArgs,
    username: string,
    registry: RegistryEntry[]
  ) => assertion(args, username, registry);

  return (
    <Layout version={version}>
      <Form onAssertion={onAssertion} onRegistration={onRegistration} />
    </Layout>
  );
}

export async function getStaticProps() {
  const { version } = await import('../../package.json');

  return {
    props: {
      version,
    },
  };
}
