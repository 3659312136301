import React from 'react';
import Head from 'next/head';
import Footer from '../components/Footer';
import Header from '../components/Header';

export interface LayoutProps {
  version?: string;
}

function Layout({ version, children }: React.PropsWithChildren<LayoutProps>) {
  return (
    <>
      <Head>
        <title>@inway/webauthn</title>
      </Head>

      <Header version={version} />

      {children}

      <Footer />
    </>
  );
}

export default Layout;
