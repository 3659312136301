import Image from 'next/image';
import React from 'react';

function Footer() {
  return (
    <footer className="mt-auto d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div className="col-md-4 d-flex align-items-center">
        <span className="text-body-secondary">&copy; 2023 InWay.pro</span>
      </div>
      <ul className="col-md-8 justify-content-end nav list-unstyled d-flex">
        <li className="nav-item">
          <a
            className="nav-link px-2"
            href="https://web.dev/articles/passkey-registration"
            target="_blank"
          >
            web.dev
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link px-2"
            href="https://passkeys.dev/device-support/"
            target="_blank"
          >
            passkeys.dev
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link px-2"
            href="https://www.w3.org/TR/webauthn-3/"
            target="_blank"
          >
            w3c.org
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
